import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { useEffect, useState } from 'react';
import { Search } from '../../AssetsHelpGang';
import { searchIdentifier } from './identifierSearchInput';
import { FormattedMessage } from 'react-intl';

const SEPARATOR = '\n';
const toList = (raw: string) => raw.split(SEPARATOR);
const fromList = (raw: string[]) => raw.join(SEPARATOR);
const nonEmpty = (list: string[]) => list.filter((it) => it.length > 0);
const isValidIdentifier = (identifier: string) => /^[0-9a-zA-Z-]{3,}$/.test(identifier);

export const EditSearchHeaderDialog = ({
    show,
    onClose,
    search,
    onSave,
}: {
    show: boolean;
    onClose: () => void;
    search: Search;
    onSave: (arg: string) => void;
}) => {
    const [searchQuery, setSearchQuery] = useState<string[]>([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (show) {
            setSearchQuery(
                toList(
                    search.searchIdentifierValue
                        .replace(/,/g, SEPARATOR)
                        .replace(/ /g, SEPARATOR)
                        .replace(/\t/g, SEPARATOR)
                ).filter((it) => it.length > 0)
            );
        }
    }, [show, setSearchQuery, search.searchIdentifierValue]);

    useEffect(() => {
        if (nonEmpty(searchQuery).every((it) => isValidIdentifier(it))) {
            setHasError(false);
        } else {
            setHasError(true);
        }
    }, [searchQuery]);

    const type = search.searchIdentifierType === searchIdentifier.SEARCH_VIN ? 'VIN' : 'Asset ID';

    return (
        <Dialog
            show={show}
            title={<FormattedMessage id={'assets-helpgang.confirmationDialog.modifySearchQuery.title'} />}
            onClose={onClose}
            body={
                <>
                    <div className={'margin-bottom-5 text-color-darker'}>
                        <FormattedMessage
                            id={'assets-helpgang.confirmationDialog.modifySearchQuery.description'}
                            values={{ type: `${type}s` }}
                        />
                    </div>
                    <div className={`form-group${hasError ? ' has-feedback has-error' : ''}`}>
                        <textarea
                            value={fromList(searchQuery)}
                            rows={10}
                            className={'form-control width-100pct'}
                            onChange={(e) => setSearchQuery(toList(e.target.value))}
                        />
                        {hasError && <span className="form-control-feedback rioglyph rioglyph-error-sign" />}
                        {hasError && (
                            <span className="help-block">
                                <span>{`Enter only valid ${type}s`}</span>
                            </span>
                        )}
                    </div>
                </>
            }
            footer={
                <div className={'btn-toolbar pull-right'}>
                    <button className={'btn btn-default'} onClick={onClose}>
                        <FormattedMessage id={'assets-helpgang.confirmationDialog.cancel'} />
                    </button>
                    <button className={'btn btn-primary'} onClick={() => onSave(searchQuery.join(','))}>
                        <FormattedMessage id={'assets-helpgang.confirmationDialog.save'} />
                    </button>
                </div>
            }
        />
    );
};
