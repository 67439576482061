import { ChangeEvent, useState } from 'react';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { FormattedMessage } from 'react-intl';
import { FormInputField } from '../FormInputField';
import { Device } from '../../../api/assetAdministrationBackendCodec';
import { MinimalAsset } from '../../AssetsHelpGang';

interface DisassociateDeviceDialogProps {
    showDialog: boolean;
    setShowDialog: (value: boolean) => void;
    asset: MinimalAsset | undefined;
    handleConfirm: (assetId: string, deviceId: string, reason: string) => void;
    device: Device | null;
}
export const DisassociateDeviceDialog = ({
    showDialog,
    setShowDialog,
    asset,
    device,
    handleConfirm: handleConfirm,
}: DisassociateDeviceDialogProps) => {
    const [confirmationText, setConfirmationText] = useState('');
    const [reason, setReason] = useState<string | undefined>(undefined);

    const resetState = () => {
        setReason(undefined);
    };

    const handleReasonChange = (_propertyName: string, value: string) => setReason(value);
    const isReasonValid = () => reason && reason.trim().length > 0;
    const expectedConfirmationText = 'DISCONNECT';
    const isConfirmationTextValid = () => expectedConfirmationText === confirmationText;
    const isFormValid = () => isReasonValid() && isConfirmationTextValid();

    const handleConfirmationTextChange = (event: ChangeEvent<HTMLInputElement>) =>
        setConfirmationText(event.target.value);

    const getModalContent = () =>
        asset ? (
            <div data-cy={'disassociate-device-modal-content'}>
                <div>
                    <FormInputField
                        dataAttribute="disassociate-device-dialog-reason-input"
                        translationId={'assets-helpgang.addFleetAdminDialog.reason'}
                        value={reason}
                        propertyName={'reason'}
                        formError={!isReasonValid() ? 'assets-helpgang.UserDialog.reason.error' : undefined}
                        required
                        onValueChange={handleReasonChange}
                    />
                </div>
                <p>
                    <FormattedMessage id={'assets-helpgang.modal.areYouSureDisassociate'} />
                </p>
                <p className="text-size-20">VIN: {asset.vin}</p>
                <p className="text-size-20">Asset Id: {asset.id}</p>
                <p className="text-size-20">Account Id: {asset.accountId}</p>
                <p>
                    <FormattedMessage id={'assets-helpgang.modal.deviceType'} />
                </p>
                {device && (
                    <>
                        <p className="text-size-20">Device-Type: {device.type}</p>
                        <p className="text-size-20">Device-Identification: {device.identification}</p>
                        <p className="text-size-20">Device-Id: {device.id}</p>
                    </>
                )}
                <p>
                    <FormattedMessage id={'assets-helpgang.modal.typeConfirmation'} />:{' '}
                    <span className="text-bold text-color-danger pointer-events-none user-select-none">
                        {expectedConfirmationText}
                    </span>
                </p>
                <div className="form-group">
                    <input
                        className="form-control"
                        type="text"
                        value={confirmationText}
                        onChange={handleConfirmationTextChange}
                        data-cy={'confirmation-input'}
                    />
                </div>
            </div>
        ) : null;

    const onClose = () => {
        resetState();
        setShowDialog(false);
    };

    const handleConfirmButtonClick = () => {
        if (asset && reason && device) {
            handleConfirm(asset.id, device.id, reason);
        }
        resetState();
    };

    return (
        <ConfirmationDialog
            show={showDialog}
            title={<FormattedMessage id={'assets-helpgang.confirmationDialog.disassociateDevice'} />}
            content={getModalContent()}
            bsSize={'sm'}
            onClickConfirm={handleConfirmButtonClick}
            onClickCancel={onClose}
            cancelButtonText={<FormattedMessage id={'assets-helpgang.confirmationDialog.cancel'} />}
            confirmButtonText={<FormattedMessage id={'assets-helpgang.confirmationDialog.disconnect'} />}
            useOverflow
            disableConfirm={!isFormValid()}
        />
    );
};
