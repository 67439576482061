import React, { useState } from 'react';
import { fetchAccountInfoById } from '../../../api/api';

export const AccountIdWithName = ({ accountId }: { accountId: string }) => {
    const [accountName, setAccountName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (isLoading) {
            return;
        }

        setIsLoading(true);
        fetchAccountInfoById(accountId)
            .then((res) => {
                setAccountName(res.accountName);
            })
            .catch((err: unknown) => {
                console.error('Could not resolve account name:', err);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            {accountName ? (
                <table className={'table table-condensed text-color-black'}>
                    <tbody>
                        <tr>
                            <td className={'padding-0 padding-bottom-5'} style={{ borderTop: 0 }}>
                                {accountId}
                            </td>
                        </tr>
                        <tr>
                            <td className={'padding-0 text-color-dark'} style={{ borderTop: 0 }}>
                                {accountName}
                            </td>
                        </tr>
                    </tbody>
                </table>
            ) : (
                <div>
                    <a href={'#'} className={'text-color-black'} onClick={onClick} style={{ textDecoration: 'none' }}>
                        {accountId}
                    </a>
                    {isLoading ? (
                        <span className={'btn btn-muted btn-loading btn-icon-only'} />
                    ) : (
                        <span className={'btn btn-muted btn-loading btn-icon-only'} style={{ visibility: 'hidden' }} />
                    )}
                </div>
            )}
        </>
    );
};
