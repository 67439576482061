import { MinimalAsset } from '../../AssetsHelpGang';

export const sortByAccountIdAndVin = (a: MinimalAsset, b: MinimalAsset) => {
    // First, compare by accountId
    if (a.accountId < b.accountId) return -1;
    if (a.accountId > b.accountId) return 1;

    // If accountIds are equal, compare by vin
    if (a.vin === b.vin) return 0;
    if (a.vin == null) return 1;
    if (b.vin == null) return -1;
    return a.vin.localeCompare(b.vin);
};
