import Notification from '@rio-cloud/rio-uikit/Notification';
import uniq from 'lodash/fp/uniq';
import { Dispatch, SetStateAction } from 'react';
import { searchIdentifier } from './assets/searchBar/identifierSearchInput';
import {
    fetchAssetByAccountIdPaginated,
    fetchAssetById,
    fetchAssetsByVin,
    fetchDevicesForAccountPaginated,
    fetchDevicesForAssetById,
} from '../api/api';
import { AssetWithDevices, Search } from './AssetsHelpGang';
import { Asset } from '../api/assetAdministrationBackendCodec';
import { RequestedIdentifierResults } from './IdentifierResults';

export const fetchAssets = async (
    search: Search,
    setAssets: (input: AssetWithDevices[] | null) => void,
    setRequestedIdentifiers: Dispatch<SetStateAction<RequestedIdentifierResults[]>>
) => {
    setAssets(null);
    try {
        const searchIdentifierValues = uniq(
            search.searchIdentifierValue
                .replace(/ /g, ',')
                .replace(/\t/g, ',')
                .split(',')
                .map((it) => it.trim())
                .filter((it) => it !== '')
        );

        let assetsWithDevices: AssetWithDevices[];
        const requestedIdentifierStates: RequestedIdentifierResults[] = [];

        if (search.accountId !== '') {
            const assets = await fetchAssetByAccountIdPaginated(search.accountId);
            let filteredAssets: Asset[] = [];

            if (
                searchIdentifierValues.length > 0 &&
                (search.searchIdentifierType === searchIdentifier.SEARCH_VIN ||
                    search.searchIdentifierType === searchIdentifier.ASSET_ID)
            ) {
                for (const searchString of searchIdentifierValues) {
                    const matchingAsset = assets.filter((asset) => {
                        if (search.searchIdentifierType === searchIdentifier.SEARCH_VIN) {
                            return searchString === asset.identification;
                        } else if (search.searchIdentifierType === searchIdentifier.ASSET_ID) {
                            return searchString === asset.id;
                        } else {
                            return false;
                        }
                    });
                    if (matchingAsset.length > 0) {
                        filteredAssets.push(...matchingAsset);
                        requestedIdentifierStates.push({ name: searchString, found: true });
                    } else {
                        requestedIdentifierStates.push({ name: searchString, found: false });
                    }
                }
            } else {
                filteredAssets = assets;
            }

            assetsWithDevices = await fetchDevicesAssetsInAccountId(search.accountId, filteredAssets);
        } else {
            const assets: Asset[] = [];
            for (const searchString of searchIdentifierValues) {
                if (search.searchIdentifierType === searchIdentifier.ASSET_ID) {
                    try {
                        const tmpAsset = await fetchAssetById(searchString);
                        requestedIdentifierStates.push({ name: searchString, found: true });
                        assets.push(tmpAsset);
                    } catch (e: unknown) {
                        console.error(e);
                        requestedIdentifierStates.push({ name: searchString, found: false });
                    }
                } else if (search.searchIdentifierType === searchIdentifier.SEARCH_VIN) {
                    try {
                        const tmpAssets = await fetchAssetsByVin(searchString);
                        requestedIdentifierStates.push({ name: searchString, found: tmpAssets.length > 0 });
                        assets.push(...tmpAssets);
                    } catch (e: unknown) {
                        console.error(e);
                        requestedIdentifierStates.push({ name: searchString, found: false });
                    }
                }
            }
            assetsWithDevices = await fetchDevicesForAssets(assets);
        }
        setAssets(assetsWithDevices);
        setRequestedIdentifiers(requestedIdentifierStates);
    } catch (error) {
        setAssets([]);
        notificationOnError(error);
    }
};

const fetchDevicesAssetsInAccountId = async (accountId: string, assetsResponse: Asset[]) => {
    const allDevicesForAccount = await fetchDevicesForAccountPaginated(accountId);
    return assetsResponse.map((asset) => {
        const devicesForAsset = allDevicesForAccount
            .filter((device) => device.assetId === asset.id)
            .map((device) => device.device);
        return { asset, devices: devicesForAsset };
    });
};

const fetchDevicesForAssets = async (assetsResponse: Asset[]) =>
    Promise.all(
        assetsResponse.map(async (asset) => {
            const deviceResponse = await fetchDevicesForAssetById(asset.id);
            return { asset, devices: deviceResponse };
        })
    );

const notificationOnError = (error: unknown) => {
    let message;
    if (error instanceof Error) message = error.message;
    else message = String(error);
    if (message !== 'NOT_FOUND') {
        Notification.error(message);
    }
};
