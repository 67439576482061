import * as t from 'io-ts';
import { decode } from './iotsDecoder';

export enum AssetStatus {
    ACTIVE = 'active',
    ARCHIVED = 'archived',
}

const asset = t.type({
    id: t.string,
    account_id: t.string,
    identification: t.union([t.string, t.undefined, t.null]),
    identification_type: t.union([t.string, t.undefined, t.null]),
    name: t.string,
    brand: t.union([t.string, t.undefined, t.null]),
    status: t.union([t.literal(AssetStatus.ACTIVE), t.literal(AssetStatus.ARCHIVED)]),
    type: t.string,
    license_plate: t.union([t.string, t.undefined, t.null]),
    license_plate_country_code: t.union([t.string, t.undefined, t.null]),
});
export type Asset = t.TypeOf<typeof asset>;

const assets = t.array(asset);
export type Assets = t.TypeOf<typeof assets>;

const device = t.type({
    id: t.string,
    identification: t.string,
    type: t.string,
});
export type Device = t.TypeOf<typeof device>;

const devices = t.array(device);
const devicesResponse = t.type({
    items: devices,
});
export type Devices = t.TypeOf<typeof devices>;

const association = t.type({
    id: t.string,
    device_id: t.string,
    asset_id: t.string,
});
export type Association = t.TypeOf<typeof association>;

const Associations = t.array(association);
export type Associations = t.TypeOf<typeof Associations>;

// { accountId, accountName: res.name, accountTenant: res.tenant }
const AccountInfo = t.type({
    id: t.string,
    name: t.string,
    tenant: t.string,
});
export type AccountInfo = t.TypeOf<typeof AccountInfo>;

// -------------------------------
// API responses
const paginationResources = t.union([
    t.undefined,
    t.intersection([
        t.partial({
            next: t.type({
                href: t.string,
            }),
        }),
        t.type({
            self: t.type({
                href: t.string,
            }),
        }),
    ]),
]);

const associationsWithEmbeddedDevicesResponseItem = t.type({
    id: t.string,
    device_id: t.string,
    asset_id: t.string,
    _embedded: t.union([
        t.undefined,
        t.type({}),
        t.type({
            device,
        }),
    ]),
});

const associationResponseItem = t.type({
    id: t.string,
    device_id: t.string,
    asset_id: t.string,
});

const associationsWithEmbeddedDevicesResponse = t.type({
    items: t.array(associationsWithEmbeddedDevicesResponseItem),
    _links: paginationResources,
});
const associationsResponse = t.type({
    items: t.array(associationResponseItem),
    _links: paginationResources,
});
const assetsResponse = t.type({
    items: assets,
    _links: paginationResources,
});

type AssociationsWithEmbeddedDevicesResponseItem = t.TypeOf<typeof associationsWithEmbeddedDevicesResponseItem>;

// -------------------------------
// Decoder
export const decodeMultipleAssetsResponse = (
    parsedObject: unknown
): { assets: Assets; nextLink: string | undefined } => {
    const decodedResponse = decode(parsedObject, assetsResponse);
    return { assets: decodedResponse.items, nextLink: decodedResponse._links?.next?.href };
};

export const decodeMultipleDevicesResponse = (parsedObject: unknown): Devices =>
    decode(parsedObject, devicesResponse).items;

export const decodeSingleAssetResponse = (parsedObject: unknown): Asset => decode(parsedObject, asset);

export const decodeAssociationResponseToDevices = (
    parsedObject: unknown
): { devices: Device[]; nextLink: string | undefined } => {
    const decodedResponse = decode(parsedObject, associationsWithEmbeddedDevicesResponse);
    return {
        devices: decodedResponse.items.map(associationToDevice).filter((it): it is Device => it !== undefined),
        nextLink: decodedResponse._links?.next?.href,
    };
};

export const decodeAssociationResponse = (
    parsedObject: unknown
): { associations: AssociationsWithEmbeddedDevicesResponseItem[]; nextLink: string | undefined } => {
    const decodedResponse = decode(parsedObject, associationsWithEmbeddedDevicesResponse);
    return {
        associations: decodedResponse.items,
        nextLink: decodedResponse._links?.next?.href,
    };
};

const associationToDevice = (item: AssociationsWithEmbeddedDevicesResponseItem): Device | undefined => {
    return item['_embedded'] && 'device' in item['_embedded'] ? item['_embedded'].device : undefined;
};

export const decodeMultipleAssociationsResponse = (parsedObject: unknown): Associations =>
    decode(parsedObject, associationsResponse).items;

export const decodeAccountInfo = (parsedObject: unknown): AccountInfo => decode(parsedObject, AccountInfo);
