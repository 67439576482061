import { SelectOption } from '@rio-cloud/rio-uikit';
import { Dispatch, KeyboardEvent, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import { Search } from '../../AssetsHelpGang';
import { IdentifierSelectComponent } from '../../IdentifierSelectComponent';
import { EditSearchHeaderDialog } from './EditSearchHeaderDialog';
import { SearchIdentifier, searchIdentifier } from './identifierSearchInput';

const identifierOptions: SelectOption[] = [
    {
        id: searchIdentifier.ASSET_ID,
        label: 'Asset ID',
    },
    {
        id: searchIdentifier.SEARCH_VIN,
        label: 'VIN',
    },
];

interface Props {
    handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
    pending: boolean;
    search: Search;
    setSearch: Dispatch<SetStateAction<Search>>;
}

export const IdentifierSearchInput = (props: Props) => {
    const { handleKeyDown, pending, search, setSearch } = props;

    const [showEditDialog, setShowEditDialog] = useState(false);
    const [hoverClear, setHoverClear] = useState(false);
    const [hoverEdit, setHoverEdit] = useState(false);

    const intl = useIntl();

    return (
        <>
            {' '}
            <EditSearchHeaderDialog
                show={showEditDialog}
                onClose={() => setShowEditDialog(false)}
                search={search}
                onSave={(searchQuery: string) => {
                    setSearch((prevState) => ({
                        ...prevState,
                        searchIdentifierValue: searchQuery,
                    }));
                    setShowEditDialog(false);
                }}
            />
            <div className="form-group form-group-lg">
                <div className="input-group input-group-lg">
                    <div className="input-group-btn identifier-select">
                        <IdentifierSelectComponent
                            identifierOptions={identifierOptions}
                            value={search.searchIdentifierType}
                            changeIdentifierSelection={(selectOption: SelectOption | undefined) => {
                                if (selectOption !== undefined) {
                                    setSearch((prevState) => {
                                        let searchIdentifierType: SearchIdentifier;
                                        if (selectOption.id === searchIdentifier.ASSET_ID) {
                                            searchIdentifierType = searchIdentifier.ASSET_ID;
                                        } else if (selectOption.id === searchIdentifier.SEARCH_VIN) {
                                            searchIdentifierType = searchIdentifier.SEARCH_VIN;
                                        } else {
                                            return prevState;
                                        }

                                        return {
                                            ...prevState,
                                            searchIdentifierType,
                                        };
                                    });
                                }
                            }}
                            data-cy={'input-asset-dropdown'}
                        />
                    </div>
                    <input
                        id="searchIdentifierInput"
                        type="text"
                        className="form-control rounded-none"
                        placeholder={
                            search.searchIdentifierType === searchIdentifier.SEARCH_VIN
                                ? 'VIN1,VIN2,...'
                                : 'AssetId1,AssetId2,...'
                        }
                        disabled={pending}
                        value={search.searchIdentifierValue}
                        onChange={(input) => {
                            setSearch((prevState) => ({
                                ...prevState,
                                searchIdentifierValue: input.target.value,
                            }));
                        }}
                        onKeyDown={handleKeyDown}
                        data-cy={'input-asset-search'}
                        aria-label={'asset identifier search input'}
                    />
                    <span className="input-group-addon display-flex gap-10">
                        <span
                            className={'rioglyph rioglyph-remove-sign' + (hoverClear ? '' : ' text-color-gray')}
                            title={intl.formatMessage({ id: 'assets-helpgang.search.clear' })}
                            onClick={() => setSearch((prevState) => ({ ...prevState, searchIdentifierValue: '' }))}
                            onMouseEnter={() => setHoverClear(true)}
                            onMouseLeave={() => setHoverClear(false)}
                            style={{
                                cursor: 'pointer',
                                visibility: search.searchIdentifierValue ? 'visible' : 'hidden',
                            }}
                        />
                        <span
                            className={
                                'rioglyph rioglyph-new-window margin-right--5' + (hoverEdit ? '' : ' text-color-gray')
                            }
                            title={intl.formatMessage({ id: 'assets-helpgang.search.edit' })}
                            onClick={() => setShowEditDialog(true)}
                            onMouseEnter={() => setHoverEdit(true)}
                            onMouseLeave={() => setHoverEdit(false)}
                            style={{ cursor: 'pointer' }}
                        />
                    </span>
                </div>
            </div>
        </>
    );
};
