import React, { ErrorInfo, ReactNode } from 'react';

export class ErrorBoundary extends React.Component<{ children?: ReactNode }> {
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(error);
        console.error(errorInfo);
    }

    render() {
        return this.props.children;
    }
}
