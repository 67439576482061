import Select from '@rio-cloud/rio-uikit/Select';
import { SelectOption } from '@rio-cloud/rio-uikit';

export const IdentifierSelectComponent = (props: IdentifierSelectComponentProps) => {
    const { identifierOptions, value, changeIdentifierSelection } = props;

    return <Select bsSize={'lg'} options={identifierOptions} onChange={changeIdentifierSelection} value={[value]} />;
};

interface IdentifierSelectComponentProps {
    identifierOptions: SelectOption[];
    changeIdentifierSelection: (selectOption: SelectOption | undefined) => void;
    value: string;
}
