import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import Notification from '@rio-cloud/rio-uikit/Notification';

interface Props {
    value: string | undefined | null;
    fieldName?: string;
    tooltipText?: string;
}
export const CopyButton = ({ value, fieldName, tooltipText }: Props) => (
    <span>
        <OverlayTrigger
            placement={'right'}
            overlay={
                <Tooltip id="tooltip" allowOnTouch>
                    {tooltipText ? tooltipText : 'Copy'}
                </Tooltip>
            }
        >
            <button
                className={'btn btn-muted btn-xs btn-icon-only'}
                onClick={(e) => {
                    e.stopPropagation();
                    if (value) {
                        navigator.clipboard.writeText(value).then(() => {
                            Notification.info(fieldName ? `Copied ${fieldName} to clipboard` : 'Copied to clipboard');
                        });
                    }
                }}
            >
                <span className={'rioglyph rioglyph-clipboard'} />
            </button>
        </OverlayTrigger>
    </span>
);
