import { combineReducers, configureStore } from '@reduxjs/toolkit';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../features/app/appSlice';

const rootReducer = combineReducers({
    lang: langReducer,
    app: appReducer,
    login: loginReducer,
    tokenHandling: tokenReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        reducer: rootReducer,
        // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...),
        preloadedState,
    });

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];
