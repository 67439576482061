import { CopyButton } from './CopyButton';
import { PropsWithChildren } from 'react';

interface Props {
    value: string | undefined | null;
    width?: number;
    fieldName?: string;
    tooltipText?: string;
}

export const ValueWithCopyButton = ({ value, width, fieldName, children, tooltipText }: PropsWithChildren<Props>) => (
    <div className={'row'}>
        <div className={width ? `col-${width}` : 'col-10'}>
            <div className={'display-flex justify-content-between align-items-center'}>
                {children === undefined ? <span>{value}</span> : children}
                <CopyButton value={value} fieldName={fieldName} tooltipText={tooltipText} />
            </div>
        </div>
    </div>
);
