import { ChangeEvent, useState } from 'react';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { FormInputField } from '../FormInputField';
import { sortByAccountIdAndVin } from './dialogUtils';
import { MinimalAsset } from '../../AssetsHelpGang';

interface AssetDeletionDialogProps {
    showDialog: boolean;
    setShowDeletionDialog: (value: boolean) => void;
    assets: MinimalAsset[];
    handleDeleteConfirm: (accountIds: string[], reason: string) => void;
}
export const AssetDeletionDialog = ({
    showDialog,
    setShowDeletionDialog,
    assets,
    handleDeleteConfirm,
}: AssetDeletionDialogProps) => {
    const [confirmationText, setConfirmationText] = useState('');
    const [reason, setReason] = useState<string | undefined>(undefined);

    const resetState = () => {
        setConfirmationText('');
        setReason(undefined);
    };

    const handleReasonChange = (_propertyName: string, value: string) => setReason(value);
    const isReasonValid = () => reason && reason.trim().length > 0;
    const isConfirmationTextValid = () => 'DELETE' === confirmationText;

    const isFormValid = () => (reason ? isReasonValid() && isConfirmationTextValid() : false);

    const handleConfirmationTextChange = (event: ChangeEvent<HTMLInputElement>) =>
        setConfirmationText(event.target.value);

    const getModalContent = () => {
        if (assets.length > 0) {
            return (
                <div data-cy={'modal-content-delete'}>
                    <div>
                        <FormInputField
                            dataAttribute="delete-account-dialog-reason-input"
                            translationId={'assets-helpgang.addFleetAdminDialog.reason'}
                            value={reason}
                            propertyName={'reason'}
                            formError={reason?.length === 0 ? 'assets-helpgang.UserDialog.reason.error' : undefined}
                            required
                            onValueChange={handleReasonChange}
                        />
                    </div>
                    {assets.length > 1 ? (
                        <FormattedMessage
                            id={'assets-helpgang.confirmationDialog.deleteAsset.count.default'}
                            values={{ total: assets.length, b: (chunks) => <b>{chunks}</b> }}
                        />
                    ) : null}
                    <p>
                        <FormattedPlural
                            value={assets.length}
                            one={<FormattedMessage id={'assets-helpgang.modal.areYouSureDelete.one'} />}
                            other={<FormattedMessage id={'assets-helpgang.modal.areYouSureDelete.other'} />}
                        />
                    </p>
                    {assets.length === 1 && assets[0] ? (
                        <>
                            <p className="text-size-20">VIN: {assets[0].vin}</p>
                            <p className="text-size-20">Asset Id: {assets[0].id}</p>
                            <p className="text-size-20">Account Id: {assets[0].accountId}</p>
                        </>
                    ) : (
                        <div className={'max-height-150 overflow-y-auto'}>
                            <table className="table table-head-filled table-condensed table-sticky-in-container">
                                <thead>
                                    <tr>
                                        <th>VIN</th>
                                        <th>Account ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {assets.sort(sortByAccountIdAndVin).map((it) => (
                                        <tr key={it.id}>
                                            <td>{it.vin}</td>
                                            <td>{it.accountId}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    <p>
                        <FormattedMessage id={'assets-helpgang.modal.typeConfirmation'} />:{' '}
                        <span className="text-bold text-color-danger pointer-events-none user-select-none">DELETE</span>
                    </p>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            value={confirmationText}
                            onChange={handleConfirmationTextChange}
                            data-cy={'delete-assets-dialog-confirmation-input'}
                        />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    const onClose = () => {
        resetState();
        setShowDeletionDialog(false);
    };

    const handleConfirmButtonClick = () => {
        if (reason) {
            handleDeleteConfirm(
                assets.map((it) => it.id),
                reason
            );
        }
        resetState();
    };

    return (
        <ConfirmationDialog
            show={showDialog}
            title={<FormattedMessage id={'assets-helpgang.confirmationDialog.deleteAsset'} />}
            content={getModalContent()}
            bsSize={'sm'}
            onClickConfirm={handleConfirmButtonClick}
            onClickCancel={onClose}
            cancelButtonText={<FormattedMessage id={'assets-helpgang.confirmationDialog.cancel'} />}
            confirmButtonText={<FormattedMessage id={'assets-helpgang.confirmationDialog.deleteAsset'} />}
            useOverflow
            disableConfirm={!isFormValid()}
        />
    );
};
