import { ChangeEvent, useState } from 'react';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { FormattedMessage, FormattedPlural } from 'react-intl';
import { FormInputField } from '../FormInputField';
import { MinimalAsset } from '../../AssetsHelpGang';
import { AssetStatus } from '../../../api/assetAdministrationBackendCodec';
import { sortByAccountIdAndVin } from './dialogUtils';

interface AssetArchivingDialogProps {
    showArchivingDialog: boolean;
    setShowArchivingDialog: (value: boolean) => void;
    assets: MinimalAsset[];
    handleArchiveConfirm: (accountIds: string[], reason: string) => void;
}
export const AssetArchivingDialog = ({
    showArchivingDialog,
    setShowArchivingDialog,
    assets,
    handleArchiveConfirm,
}: AssetArchivingDialogProps) => {
    const [confirmationText, setConfirmationText] = useState('');
    const [reason, setReason] = useState<string | undefined>(undefined);

    const resetState = () => {
        setConfirmationText('');
        setReason(undefined);
    };

    const handleReasonChange = (_propertyName: string, value: string) => setReason(value);
    const isReasonValid = () => reason && reason.trim().length > 0;
    const isConfirmationTextValid = () => 'ARCHIVE' === confirmationText;

    const isFormValid = () => (reason ? isReasonValid() && isConfirmationTextValid() : false);

    const handleConfirmationTextChange = (event: ChangeEvent<HTMLInputElement>) =>
        setConfirmationText(event.target.value);

    const assetsToArchive = assets.filter((it) => it.status !== AssetStatus.ARCHIVED);

    const getModalContent = () => {
        if (assetsToArchive.length > 0) {
            return (
                <div data-cy={'modal-content-archive'}>
                    <div>
                        <FormInputField
                            dataAttribute="archive-asset-dialog-reason-input"
                            translationId={'assets-helpgang.addFleetAdminDialog.reason'}
                            value={reason}
                            propertyName={'reason'}
                            formError={reason?.length === 0 ? 'assets-helpgang.UserDialog.reason.error' : undefined}
                            required
                            onValueChange={handleReasonChange}
                        />
                    </div>
                    {assets.length === assetsToArchive.length ? (
                        assetsToArchive.length > 1 ? (
                            <FormattedMessage
                                id={'assets-helpgang.confirmationDialog.archiveAsset.count.default'}
                                values={{ total: assets.length, b: (chunks) => <b>{chunks}</b> }}
                            />
                        ) : null
                    ) : (
                        <FormattedMessage
                            id={'assets-helpgang.confirmationDialog.archiveAsset.count.someAlreadyArchived'}
                            values={{
                                actual: assetsToArchive.length,
                                total: assets.length,
                                difference: assets.length - assetsToArchive.length,
                                b: (chunks) => <b>{chunks}</b>,
                            }}
                        />
                    )}
                    <p>
                        <FormattedPlural
                            value={assetsToArchive.length}
                            one={<FormattedMessage id={'assets-helpgang.modal.areYouSureArchive.one'} />}
                            other={<FormattedMessage id={'assets-helpgang.modal.areYouSureArchive.other'} />}
                        />
                    </p>
                    {assetsToArchive.length === 1 && assetsToArchive[0] ? (
                        <>
                            <p className="text-size-20">VIN: {assetsToArchive[0].vin}</p>
                            <p className="text-size-20">Asset Id: {assetsToArchive[0].id}</p>
                            <p className="text-size-20">Account Id: {assetsToArchive[0].accountId}</p>
                        </>
                    ) : (
                        <div className={'max-height-150 overflow-y-auto'}>
                            <table className="table table-head-filled table-condensed table-sticky-in-container">
                                <thead>
                                    <tr>
                                        <th>VIN</th>
                                        <th>Account ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {assetsToArchive.sort(sortByAccountIdAndVin).map((it) => (
                                        <tr key={it.id}>
                                            <td>{it.vin}</td>
                                            <td>{it.accountId}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    <p>
                        <FormattedMessage id={'assets-helpgang.modal.typeConfirmation'} />:{' '}
                        <span className="text-bold text-color-danger pointer-events-none user-select-none">
                            ARCHIVE
                        </span>
                    </p>
                    <div className="form-group">
                        <input
                            className="form-control"
                            type="text"
                            value={confirmationText}
                            onChange={handleConfirmationTextChange}
                            data-cy={'archive-asset-dialog-confirmation-input'}
                        />
                    </div>
                </div>
            );
        } else if (assetsToArchive.length === 0 && assets.length > 0) {
            return (
                <div data-cy={'modal-content-archive'}>
                    <FormattedMessage id={'assets-helpgang.confirmationDialog.archiveAsset.allAlreadyArchived'} />
                </div>
            );
        } else {
            return null;
        }
    };

    const onClose = () => {
        resetState();
        setShowArchivingDialog(false);
    };

    const handleConfirmButtonClick = () => {
        if (reason) {
            handleArchiveConfirm(
                assetsToArchive.map((it) => it.id),
                reason
            );
        }
        resetState();
    };

    return (
        <ConfirmationDialog
            show={showArchivingDialog}
            title={<FormattedMessage id={'assets-helpgang.confirmationDialog.archiveAsset'} />}
            content={getModalContent()}
            bsSize={'sm'}
            onClickConfirm={handleConfirmButtonClick}
            onClickCancel={onClose}
            cancelButtonText={<FormattedMessage id={'assets-helpgang.confirmationDialog.cancel'} />}
            confirmButtonText={<FormattedMessage id={'assets-helpgang.confirmationDialog.archiveAsset'} />}
            useOverflow
            disableConfirm={!isFormValid()}
        />
    );
};
