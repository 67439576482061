import { accessToken } from '../../configuration/tokenHandling/accessToken';
import { config } from '../../config';
import {
    Asset,
    AssetStatus,
    Association,
    decodeAssociationResponse,
    decodeAssociationResponseToDevices,
    decodeMultipleAssociationsResponse,
    decodeMultipleAssetsResponse,
    decodeMultipleDevicesResponse,
    decodeSingleAssetResponse,
    Device,
    decodeAccountInfo,
} from './assetAdministrationBackendCodec';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { v4 as uuidv4 } from 'uuid';
import { getSingleOrThrow } from '../../utils';

const RIO_AUDIT_LOG_HEADER = 'x-rio-audit-reason';

const ASSET_ADMIN_FETCH_LIMIT = parseInt(config.assetsAdministrationFetchLimit ?? '1000', 10);

const getRequest = (): RequestInit => ({
    method: 'GET',
    headers: {
        Authorization: `Bearer ${accessToken.getAccessToken()}`,
    },
});

const deleteRequest = (reason: string): RequestInit => {
    return {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${accessToken.getAccessToken()}`,
            [RIO_AUDIT_LOG_HEADER]: reason,
        },
    };
};
const putAssetRequest = (reason: string, asset: Asset): RequestInit => {
    return {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${accessToken.getAccessToken()}`,
            [RIO_AUDIT_LOG_HEADER]: reason,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(asset),
    };
};

const putAssociationRequest = (reason: string, association: Association): RequestInit => {
    return {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${accessToken.getAccessToken()}`,
            [RIO_AUDIT_LOG_HEADER]: reason,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(association),
    };
};

const onRejected = (error?: Error): Promise<never> => (error ? Promise.reject(error) : Promise.reject());

const okOrReject = (
    response: Response = {} as Response,
    expectedStatusCode?: number
): Promise<Response> | Promise<never> => {
    if (expectedStatusCode && response.status !== expectedStatusCode) {
        return Promise.reject(new Error('UNEXPECTED_STATUS_CODE'));
    } else if (expectedStatusCode && response.status === expectedStatusCode) {
        return Promise.resolve(response);
    }

    if (response.status === 401) {
        return Promise.reject(new Error('UNAUTHENTICATED'));
    } else if (response.status === 403) {
        return Promise.reject(new Error('ACCESS_DENIED'));
    } else if (response.status === 404) {
        return Promise.reject(new Error('NOT_FOUND'));
    } else if (response.ok) {
        return Promise.resolve(response);
    } else {
        console.error(new Error(`${response.status} Backend error: ${response.statusText}`));
    }
    return Promise.reject(new Error('Backend error'));
};

const jsonOrReject = (response: Response): Promise<unknown> =>
    response.json().catch((error) => {
        console.error(new Error(`${response.status} Invalid payload: ${error.message}`));
        return Promise.reject(error);
    });

export const fetchAssetsByVin = async (vin: string): Promise<Asset[]> => {
    try {
        const result0 = await fetch(
            `${config.backend.ASSET_ADMINISTRATION_SERVICE}/assets?identification=${vin}&identification_type=vin`,
            getRequest()
        );
        const response = await okOrReject(result0);
        const json = await jsonOrReject(response);
        return decodeMultipleAssetsResponse(json).assets;
    } catch (error) {
        return await onRejected(error as unknown as Error);
    }
};

export const fetchDeviceByIdentification = (identification: string, deviceType: string): Promise<Device[]> =>
    fetch(
        `${config.backend.ASSET_ADMINISTRATION_SERVICE}/devices` +
            `?identification=${identification}&device_type=${deviceType}`,
        getRequest()
    )
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeMultipleDevicesResponse)
        .catch(onRejected);

export const fetchAssociationsByDeviceId = (deviceId: string): Promise<Association[]> =>
    fetch(`${config.backend.ASSET_ADMINISTRATION_SERVICE}/associations?device_id=${deviceId}`, getRequest())
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeMultipleAssociationsResponse)
        .catch(onRejected);

export const fetchAssetByAccountIdPaginated = async (accountId: string): Promise<Asset[]> => {
    try {
        const assets: Asset[] = [];
        let nextLink: string | undefined =
            `${config.backend.ASSET_ADMINISTRATION_SERVICE}/assets?account_id=${accountId}&limit=${ASSET_ADMIN_FETCH_LIMIT}`;
        while (nextLink !== undefined) {
            const result0 = await fetch(nextLink, getRequest());
            const response = await okOrReject(result0);
            const json = await jsonOrReject(response);
            const parsedObject = decodeMultipleAssetsResponse(json);
            assets.push(...parsedObject.assets);
            nextLink = parsedObject.nextLink;
        }
        return assets;
    } catch (error) {
        return await onRejected(error as unknown as Error);
    }
};

export const fetchAssetById = (assetId: string): Promise<Asset> =>
    fetch(`${config.backend.ASSET_ADMINISTRATION_SERVICE}/assets/${assetId}`, getRequest())
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeSingleAssetResponse)
        .catch(onRejected);

export const fetchDevicesForAssetById = async (assetId: string): Promise<Device[]> => {
    try {
        const result0 = await fetch(
            `${config.backend.ASSET_ADMINISTRATION_SERVICE}/associations?asset_id=${assetId}&embed=(device)`,
            getRequest()
        );
        const response = await okOrReject(result0);
        const json = await jsonOrReject(response);
        const parsedObject = decodeAssociationResponseToDevices(json);
        return parsedObject.devices;
    } catch (error) {
        return await onRejected(error as unknown as Error);
    }
};

export const fetchDevicesForAccountPaginated = async (
    accountId: string
): Promise<{ device: Device; assetId: string }[]> => {
    try {
        let nextLink: string | undefined =
            `${config.backend.ASSET_ADMINISTRATION_SERVICE}/associations?account_id=${accountId}&embed=(device)&limit=${ASSET_ADMIN_FETCH_LIMIT}`;
        const devices: { device: Device; assetId: string }[] = [];

        while (nextLink !== undefined) {
            const result0 = await fetch(nextLink, getRequest());
            const response = await okOrReject(result0);
            const json = await jsonOrReject(response);
            const parsedObject = decodeAssociationResponse(json);

            const enrichedDevices = parsedObject.associations
                .map((association) =>
                    association['_embedded'] && 'device' in association['_embedded']
                        ? { device: association['_embedded'].device, assetId: association.asset_id }
                        : undefined
                )
                .filter((it) => it !== undefined);

            devices.push(...enrichedDevices);
            nextLink = parsedObject.nextLink;
        }
        return devices;
    } catch (error) {
        return await onRejected(error as unknown as Error);
    }
};

export const deleteAsset = (assetId: string, reason: string): Promise<unknown> =>
    fetch(`${config.backend.ASSET_ADMINISTRATION_SERVICE}/assets/${assetId}`, deleteRequest(reason))
        .then((res) => okOrReject(res, 202))
        .catch(onRejected);

export const archiveAsset = (assetId: string, reason: string): Promise<unknown> =>
    fetchAssetById(assetId)
        .then((res) => setAssetStatus(res, AssetStatus.ARCHIVED))
        .then((res) =>
            fetch(`${config.backend.ASSET_ADMINISTRATION_SERVICE}/assets/${assetId}`, putAssetRequest(reason, res))
        )
        .then((res) => okOrReject(res, 202))
        .catch(onRejected);

export const associateDeviceToAsset = (
    assetId: string,
    deviceIdentification: string,
    deviceType: string,
    reason: string
): Promise<unknown> =>
    fetchDeviceByIdentification(deviceIdentification, deviceType)
        .then((devices) =>
            getSingleOrThrow<Device>(
                devices,
                (devicesCount: number) => `Exactly one device must match, but found ${devicesCount.toString()}`
            )
        )
        .then((device: Device) => {
            const associationId = uuidv4().toString();
            return fetch(
                `${config.backend.ASSET_ADMINISTRATION_SERVICE}/associations/${associationId}`,
                putAssociationRequest(reason, { id: associationId, asset_id: assetId, device_id: device.id })
            );
        })
        .then((res) => okOrReject(res, 202))
        .then(() => Notification.success('Association created'))
        .catch(onRejected);

export const disassociateDeviceFromAsset = (assetId: string, deviceId: string, reason: string): Promise<unknown> =>
    fetchAssociationsByDeviceId(deviceId)
        .then((associations) =>
            associations.filter((association) => association.asset_id === assetId && association.device_id === deviceId)
        )
        .then((associations) =>
            getSingleOrThrow(
                associations,
                (associationsCount: number) =>
                    `Exactly one association must match, but found ${associationsCount.toString()}`
            )
        )
        .then((association) =>
            fetch(
                `${config.backend.ASSET_ADMINISTRATION_SERVICE}/associations/${association.id}`,
                deleteRequest(reason)
            )
        )
        .then((res) => okOrReject(res, 202))
        .then(() => Notification.success('Association created'))
        .catch(onRejected);

export const activateAsset = (assetId: string, reason: string): Promise<unknown> =>
    fetchAssetById(assetId)
        .then((res) => setAssetStatus(res, AssetStatus.ACTIVE))
        .then((res) =>
            fetch(`${config.backend.ASSET_ADMINISTRATION_SERVICE}/assets/${assetId}`, putAssetRequest(reason, res))
        )
        .then((res) => okOrReject(res, 202))
        .then(() => Notification.success('Asset activated'))
        .catch(onRejected);

export const fetchAccountInfoById = (
    accountId: string
): Promise<{ accountId: string; accountName: string; accountTenant: string }> =>
    fetch(`${config.backend.ACCOUNTS_SERVICE}/${accountId}`, getRequest())
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeAccountInfo)
        .then((res) => ({ accountId, accountName: res.name, accountTenant: res.tenant }))
        .catch(onRejected);

const setAssetStatus = (asset: Asset, newStatus: AssetStatus): Asset => {
    return {
        ...asset,
        status: newStatus,
    };
};
