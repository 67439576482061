import { Search } from '../../AssetsHelpGang';
import { Dispatch, KeyboardEvent, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';

interface Props {
    handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
    pending: boolean;
    search: Search;
    setSearch: Dispatch<SetStateAction<Search>>;
}

export const AccountIdSearchInput = (props: Props) => {
    const { handleKeyDown, pending, search, setSearch } = props;
    const [hoverClear, setHoverClear] = useState(false);

    const intl = useIntl();

    return (
        <div className="form-group form-group-lg">
            <div className="input-group input-group-lg">
                <span className="input-group-btn">
                    <button
                        className={'btn btn-default btn-lg text-color-darker border-color-gray'}
                        style={{ pointerEvents: 'none' }}
                    >
                        Account ID
                    </button>
                </span>
                <input
                    id="searchIdentifierInputAccountId"
                    type="text"
                    className="form-control rounded-none"
                    placeholder="Account ID"
                    disabled={pending}
                    value={search.accountId}
                    onChange={(input) => setSearch((prevState) => ({ ...prevState, accountId: input.target.value }))}
                    onKeyDown={handleKeyDown}
                    data-cy={'input-asset-search-account-id'}
                    aria-label={'account id search input'}
                />
                <span className="input-group-addon">
                    <span
                        className={
                            'rioglyph rioglyph-remove-sign margin-right--5' + (hoverClear ? '' : ' text-color-gray')
                        }
                        title={intl.formatMessage({ id: 'assets-helpgang.search.clear' })}
                        onClick={() => setSearch((prevState) => ({ ...prevState, accountId: '' }))}
                        onMouseEnter={() => setHoverClear(true)}
                        onMouseLeave={() => setHoverClear(false)}
                        style={{ cursor: 'pointer', visibility: search.accountId ? 'visible' : 'hidden' }}
                    />
                </span>
            </div>
        </div>
    );
};
