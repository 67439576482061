export const DeviceStateWrapper = () => {
    const url = 'https://corecon-admin-web.vcp-connector.rio.cloud/#/device-state/home';
    return (
        <div
            className="iframe-wrapper height-100pct"
            style={{ marginTop: '-80px', marginLeft: '-30px', marginRight: '-30px' }}
        >
            <iframe className="unstyled bg-lighter full-window" src={url} />
        </div>
    );
};
