import { FormattedMessage } from 'react-intl';

export interface RequestedIdentifierResults {
    name: string;
    found: boolean;
}

interface Props {
    requestedIdentifierResults: RequestedIdentifierResults[];
}

export const IdentifierResults = (props: Props) => {
    const { requestedIdentifierResults } = props;
    const found = requestedIdentifierResults.filter((it) => it.found);
    const notFound = requestedIdentifierResults.filter((it) => !it.found);

    return (
        <div className={'margin-bottom-15'}>
            {!!notFound.length && (
                <div className={'row margin-bottom-10'}>
                    <div className={'col-xs-12'}>
                        <div className="display-flex flex-wrap gap-10">
                            <span style={{ minWidth: '95px' }}>
                                <FormattedMessage
                                    id={'assets-helpgang.search.results.notFound'}
                                    values={{ count: notFound.length }}
                                />
                            </span>
                            {notFound.map((item, idx) => (
                                <NotFoundResult name={item.name} key={idx} />
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {!!found.length && (
                <div className={'row margin-bottom-10'}>
                    <div className={'col-xs-12'}>
                        <div className="display-flex flex-wrap gap-10">
                            <span style={{ minWidth: '95px' }}>
                                <FormattedMessage
                                    id={'assets-helpgang.search.results.found'}
                                    values={{ count: found.length }}
                                />
                            </span>
                            {found.map((item, idx) => (
                                <FoundResult name={item.name} key={idx} />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const FoundResult = ({ name }: { name: string }) => (
    <div className="label label-success label-filled" style={{ cursor: 'default', textTransform: 'none' }}>
        {name}
    </div>
);

const NotFoundResult = ({ name }: { name: string }) => (
    <div className="label label-warning label-filled" style={{ cursor: 'default', textTransform: 'none' }}>
        {name}
    </div>
);
