export const AssetType = ({ type }: { type: string }) => {
    const classname: string[] = ['rioglyph'];
    if (type === 'truck') {
        classname.push('rioglyph-truck');
    } else if (type === 'van') {
        classname.push('rioglyph-van');
    } else if (type === 'bus') {
        classname.push('rioglyph-bus');
    } else {
        classname.push('rioglyph-question-sign');
    }
    const capitalizedType = type[0]!.toUpperCase() + type.slice(1);
    return (
        <>
            <span className={classname.join(' ')} />
            <span className={'margin-left-5'}>{capitalizedType}</span>
        </>
    );
};
