import { Dispatch, KeyboardEvent, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import { Search } from '../../AssetsHelpGang';
import { AccountIdSearchInput } from './AccountIdSearchInput';
import { IdentifierSearchInput } from './IdentifierSearchInput';

interface SearchHeaderProps {
    pending: boolean;
    search: Search;
    setSearch: Dispatch<SetStateAction<Search>>;
    triggerFetchAssets: () => void;
}

export const SearchHeader = (props: SearchHeaderProps) => {
    const { pending, search, setSearch, triggerFetchAssets } = props;

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            triggerFetchAssets();
        }
    };
    return (
        <>
            {/* Account ID search field */}
            <div className="row">
                <div className="display-flex justify-content-center">
                    <div className="col-xs-3">
                        <AccountIdSearchInput
                            handleKeyDown={handleKeyDown}
                            pending={pending}
                            search={search}
                            setSearch={setSearch}
                        />
                    </div>

                    {/* Identifier/Asset ID search field */}
                    <div className="col-xs-4">
                        <IdentifierSearchInput
                            handleKeyDown={handleKeyDown}
                            pending={pending}
                            search={search}
                            setSearch={setSearch}
                        />
                    </div>

                    <div className="col-xs-1">
                        <button
                            className={`btn btn-primary btn-lg${pending ? ' btn-loading disabled' : ''}`}
                            id="searchButton"
                            type="button"
                            onClick={triggerFetchAssets}
                            disabled={search.accountId === '' && search.searchIdentifierValue === ''}
                            aria-label={'search button'}
                        >
                            <FormattedMessage id={'assets-helpgang.search.btnText'} />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
