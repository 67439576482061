import { ChangeEvent, useState } from 'react';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { FormattedMessage } from 'react-intl';
import { FormInputField } from '../FormInputField';
import { MinimalAsset } from '../../AssetsHelpGang';

interface AssetActivationDialogProps {
    showActivationDialog: boolean;
    setShowActivationDialog: (value: boolean) => void;
    asset: MinimalAsset | undefined;
    handleActivateConfirm: (accountId: string, reason: string) => void;
}
export const AssetActivationDialog = ({
    showActivationDialog,
    setShowActivationDialog,
    asset,
    handleActivateConfirm,
}: AssetActivationDialogProps) => {
    const [confirmationText, setConfirmationText] = useState('');
    const [reason, setReason] = useState<string | undefined>(undefined);

    const resetState = () => {
        setConfirmationText('');
        setReason(undefined);
    };

    const handleReasonChange = (_propertyName: string, value: string) => setReason(value);
    const isReasonValid = () => reason && reason.trim().length > 0;
    const isConfirmationTextValid = () => 'ACTIVATE' === confirmationText;

    const isFormValid = () => (reason ? isReasonValid() && isConfirmationTextValid() : false);

    const handleConfirmationTextChange = (event: ChangeEvent<HTMLInputElement>) =>
        setConfirmationText(event.target.value);

    const getModalContent = () =>
        asset ? (
            <div data-cy={'modal-content-activate'}>
                <div>
                    <FormInputField
                        dataAttribute="activate-asset-dialog-reason-input"
                        translationId={'assets-helpgang.addFleetAdminDialog.reason'}
                        value={reason}
                        propertyName={'reason'}
                        formError={reason?.length === 0 ? 'assets-helpgang.UserDialog.reason.error' : undefined}
                        required
                        onValueChange={handleReasonChange}
                    />
                </div>
                <p>
                    <FormattedMessage id={'assets-helpgang.modal.areYouSureActivate'} />
                </p>
                <p className="text-size-20">VIN: {asset.vin}</p>
                <p className="text-size-20">Asset Id: {asset.id}</p>
                <p className="text-size-20">Account Id: {asset.accountId}</p>
                <p>
                    <FormattedMessage id={'assets-helpgang.modal.typeConfirmation'} />:{' '}
                    <span className="text-bold text-color-danger pointer-events-none user-select-none">ACTIVATE</span>
                </p>
                <div className="form-group">
                    <input
                        className="form-control"
                        type="text"
                        value={confirmationText}
                        onChange={handleConfirmationTextChange}
                        data-cy={'activate-asset-dialog-confirmation-input'}
                    />
                </div>
            </div>
        ) : null;

    const onClose = () => {
        resetState();
        setShowActivationDialog(false);
    };

    const handleConfirmButtonClick = () => {
        if (asset && reason) {
            handleActivateConfirm(asset.id, reason);
        }
        resetState();
    };

    return (
        <ConfirmationDialog
            show={showActivationDialog}
            title={<FormattedMessage id={'assets-helpgang.confirmationDialog.activateAsset'} />}
            content={getModalContent()}
            bsSize={'sm'}
            onClickConfirm={handleConfirmButtonClick}
            onClickCancel={onClose}
            cancelButtonText={<FormattedMessage id={'assets-helpgang.confirmationDialog.cancel'} />}
            confirmButtonText={<FormattedMessage id={'assets-helpgang.confirmationDialog.activateAsset'} />}
            useOverflow
            disableConfirm={!isFormValid()}
        />
    );
};
